import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import NumberFormat from 'react-number-format';

const useStyles = makeStyles(
	() => ({
		root: {
		},
		title: {
			fontSize: 14,
		}
	})
);


const Component = ({
	stats
}) => {

	const classes = useStyles();
	const { value, delta, growth } = stats;

	return (
		<Card className={classes.root}>
			<CardContent>
				<Typography className={classes.title} color="textSecondary" gutterBottom>Last min</Typography>
				<Typography variant="h3" component="h3">
					<NumberFormat value={value} displayType="text" decimalScale={2} thousandSeparator={true} />
				</Typography>
				<Typography variant="h5" component="h2">
					<NumberFormat value={delta} displayType="text" decimalScale={2} thousandSeparator={true} />
					<span> / </span>
					<NumberFormat 
						value={100 * growth} 
						displayType="text" 
						decimalScale={2} 
						suffix='%' 
						prefix={growth > 0 ? '+' :  ''} 
						/>
				</Typography>
			</CardContent>
		</Card>
	);
};

export default Component;