import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Current from './CurrentPrice';
import LastMax from './LastMax';
import LastMin from './LastMin';


const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(2),
		flexGrow: 1
	},
}));


const Component = ({
	stats
}) => {

	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Grid container spacing={3}>
				<Grid item xs>
					<Current current={stats.current} />
				</Grid>
				<Grid item xs>
					<LastMax stats={stats.lastPeak} />
				</Grid>
				<Grid item xs>
					<LastMin stats={stats.lastValley} />
				</Grid>
			</Grid>
			{/* <Grid container spacing={3}>
				{stats.percentiles.map(
					p => (
						<Grid item xs key={p.percentile}>
							<Percentile stats={p} />
						</Grid>
					)
				)}
			</Grid> */}
		</div>
	);
};

export default Component;
