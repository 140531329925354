import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine } from 'recharts';
import NumberFormat from 'react-number-format';



const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(2),
		padding: theme.spacing(1),
		textAlign: 'center'
	},
	wrapper: {
		height: '50vh',
	},
	tooltip: {
		backgroundColor: 'rgba(128, 128, 128, 0.1)',
		padding: theme.spacing(0.1, 1),
	}
}));


const CustomTooltip = ({ active, payload, className }) => {
	if (active) {
		const { payload: p } = payload[0];
		return (
			<div className={className}>
				<p>{p.date}</p>
				<p>
					<span>Drop: </span>
					<NumberFormat 
						value={p.growth} 
						displayType="text" 
						decimalScale={2}
						suffix='%' 
						/>
				</p>
				<p>
					<span>Percentile: </span>
					<NumberFormat 
						value={p.percentile} 
						displayType="text" 
						decimalScale={0}
						/>
				</p>
			</div>
		);
	}

	return null;
};



const Component = ({
	stats
}) => {

	const classes = useStyles();
	const { drops, lastPeak } = stats;
	const count = drops.length;

	const data = [ ...drops ].reverse().map(
		(d, index) => ({
			...d,
			growth: d.growth * 100,
			percentile: 100 * index / count
		})
	);
	
	return (
		<Card className={classes.root}>
			<h2>Opportunity: Current Drop vs. Historical Percentiles</h2>
			<div className={classes.wrapper}>
				<ResponsiveContainer width="100%" height="100%">
					<LineChart data={data}>
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis
							dataKey="percentile"
							type="number"
						/>
						<YAxis type="number" />
						<Tooltip content={<CustomTooltip />} className={classes.tooltip}  />
						<ReferenceLine y={100 * lastPeak.growth} label="Current drop" stroke="#c00" strokeDasharray="4 4" />
						<Line
							type="monotone"
							dataKey="growth"
							stroke="#8884d8"
							dot={{ r: 2 }}
							activeDot={{ r: 4 }}
							unit="%"
							/>
					</LineChart>
				</ResponsiveContainer>
			</div>
		</Card>
	);
};

export default Component;
