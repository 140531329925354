import React from 'react';
import { Link } from 'react-router-dom';


import './Header.css';


const Component = ({
	subtitle
}) => {

	const Subtitle = () => subtitle ? 
		<span> / {subtitle}</span> :
		<></>;

	return (
		<header>
			<span className="Home">
				<Link to="/">Finance</Link>
				<Subtitle />
			</span>
		</header>
	);
};

export default Component;
