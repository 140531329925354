import React from 'react';

import './Footer.css';


const Component = () => (
	<footer>This site is not intended as financial or investment advice. Please make your own assessments or contact your financial advisor.</footer>
);

export default Component;
