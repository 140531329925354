import React, { useState, useEffect } from 'react';
import { readRemoteFile } from 'react-papaparse';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

import { API_ROOT } from '../../env';
import { process } from './data.service';
import TrendChart from './TrendChart';
import PercentileChart from './PercentileChart';
import RecoveryChart from './RecoveryChart';
import Kpi from './Kpi';

const useStyles = makeStyles((theme) => ({
	loading: {
		marginTop: theme.spacing(2)
	},
}));

const getUrl = (symbol) => {
	const end = Math.round(Date.now() / 1000);
	const start = end - 5 * 365 * 24 * 60 * 60;
	return `${API_ROOT}/history?symbol=${symbol}&start=${start}&end=${end}`;
}


const Component = ({
	symbol
}) => {

	const classes = useStyles();

	const [stats, setStats] = useState(null);
	const [loading, setLoading] = useState(true);
	// console.log('loading', loading);

	useEffect(
		() => {
			if (!symbol) {
				setLoading(false);
				return;
			}
			setLoading(true);
			readRemoteFile(
				getUrl(symbol), 
				{
					download: true,
					header: true,
					dynamicTyping: true,
					complete: (result) => {
						const processed = process(result.data);
						setStats(processed);
						setLoading(false);
					}
				}
			);
		},
		[ symbol ]
	);

	if (!symbol) {
		return (
			<></>
		);
	}

	if (loading || !stats) {
		return (
			<div className={classes.loading}>
				<LinearProgress />
			</div>
		);	
	}

	return (
		<>
			<Kpi stats={stats}></Kpi>
			<TrendChart stats={stats} />
			<PercentileChart stats={stats} />
			<RecoveryChart stats={stats} />
		</>
	);

};

export default Component;