import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine } from 'recharts';
import moment from 'moment';
import NumberFormat from 'react-number-format';


const metricName = 'Price';

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(2),
		padding: theme.spacing(1),
		textAlign: 'center'
	},
	wrapper: {
		height: '50vh',
	},
	tooltip: {
		backgroundColor: 'rgba(128, 128, 128, 0.1)',
		padding: theme.spacing(0.1, 1),
	}
}));


const tickFormatter = (time) => moment(time).format('MM/yy');


const CustomTooltip = ({ active, payload, className }) => {
	if (active) {
		const { payload: p } = payload[0];
		return (
			<div className={className}>
				<p>{p.date}</p>
				<p>
					<span>{metricName}: </span>
					<NumberFormat 
						value={p.value} 
						displayType="text" 
						decimalScale={2} 
						thousandSeparator={true}
						/>
				</p>
			</div>
		);
	}

	return null;
};



const Component = ({
	stats
}) => {

	const classes = useStyles();
	const { trend } = stats;
	
	const timeRange = [trend[0].time, trend[trend.length - 1].time];
	const minValue = trend.length === 0 ?
		0 : 
		trend.reduce(
			(a, p) => Math.min(a, p.value),
			trend[0].value
		);
	const valueRange = [ Math.floor(minValue), Math.ceil(stats.lastPeak.value) ];

	return (
		<Card className={classes.root}>
			<h2>Trend: Peaks and Valleys</h2>
			<div className={classes.wrapper}>
				<ResponsiveContainer width="100%" height="100%">
					<LineChart data={trend}>
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis
							dataKey="time"
							domain={timeRange}
							scale="time"
							type="number"
							tickFormatter={tickFormatter}
							name="Time"
						/>
						<YAxis type="number" domain={valueRange} />
						<Tooltip content={<CustomTooltip />} className={classes.tooltip}  />
						<ReferenceLine y={stats.current.value} label="Last price" stroke="#c00" strokeDasharray="3 3" />
						<Line
							type="monotone"
							dataKey="value"
							stroke="#8884d8"
							dot={null}
							activeDot={{ r: 4 }}
						/>
					</LineChart>
				</ResponsiveContainer>
			</div>
		</Card>
	);
};

export default Component;
