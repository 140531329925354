import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
	  width: 300
  },
  title: {
    fontSize: 14,
  },
  description: {
    marginTop: 12,
  }
});

export default function SectionCard() {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="h5" component="h2">
          Drop Analysis
        </Typography>
        <Typography variant="body2" component="p" className={classes.description}>
          Custom stock analysis looking at the highs and lows of a particular stock.
          Useful for those who want to buy on the dip.
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" href="/drop-analysis">Launch</Button>
      </CardActions>
    </Card>
  );
}
