import React from 'react';  
import { Route, Router } from 'react-router-dom';

import history from './utils/history';
import { SectionList, DropAnalysis } from './containers';

import './App.css';

function App() {
  return (
    <div className="App">
        <Router history={history}>
          <Route path='/' component={SectionList} exact={true} />
          <Route path='/drop-analysis/:symbol?' component={DropAnalysis} />
        </Router>
      </div>
  );
}

export default App;
