import { findReverse } from '../../utils';

// const dateDiff = (d1, d2) => Math.round(
// 	moment.duration(moment(d1).diff(moment(d2))).asDays()
// );


/**
 * Get the peaks from an array of records.
 * A peak is a record with a values greater than the 2 adjacent records AND greater than the highest previous peak.
 * @param {*} records Pre-sorted historical records.
 */
export const getPeaks = (records) => {
	const peaks = [];
	let max = 0;
	let previousValue = 0;

	for (let index = 0;  index < records.length; index++) {
		const record = records[index];
		const { high } = record;
		const next = records[index + 1];
		const nextValue = (next && next.high) || 0;

		if (high > max && high > previousValue && high > nextValue) {
			max = high;
			peaks.push({ 
				date: record.date,
				time: record.time,
				value: high,
				isPeak: true 
			});
		}
	}
	return peaks;
};



/**
 * Get the valleys between peaks.
 * TODO: Optimize this function.
 * @param {*} records 
 * @param {*} peaks 
 */
export const getValleys = (records, peaks) => {
	const valleys = [];
	const peakTimes = peaks.map(p => p.time);

	for (let peakIndex = 0; peakIndex < peakTimes.length; peakIndex++) {
		const startTime = peakTimes[peakIndex];
		const endTime = peakTimes[peakIndex + 1] || Infinity;

		const slice = records.filter(
			r => r.time > startTime && r.time < endTime
		);

		const valley = slice.length && slice.reduce(
			(acc, item) => acc.low < item.low ? acc : item	
		);

		if (valley) {
			valleys.push({ 
				date: valley.date,
				time: valley.time,
				value: valley.low,
				isValley: true 
			});
		}
	}

	return valleys;
};



export const getPeaksAndValleys = (records) => {
	const peaks = getPeaks(records);
	const valleys = getValleys(records, peaks);
	return [].concat(peaks, valleys).sort(
		(r1, r2) => r1.time - r2.time
	).map(
		(r, i, a) => {
			const previous = a[i - 1];
			const growth = previous ? 
				r.value / previous.value - 1 :
				0;
			return {
				...r,
				growth
			};
		}
	);
};


const getDrops = (trend) => {
	return trend.filter(
		r => r.isValley
	).sort(
		(r1, r2) => r1.growth - r2.growth
	);
};



const normalize = (records) => records.map(
	r => ({
		date: r.Date,
		time: Date.parse(r.Date),
		high: r.High,
		low: r.Low,
		close: r.Close
	})
).sort(
	(r1, r2) => r1 - r2
);



export const process = (data) => {
	const records = normalize(data);

	const { close: currentValue } = records[records.length - 1];
	const { close: previousClose } = records[records.length - 2];

	const trend = getPeaksAndValleys(records);
	// console.log(trend);

	const lastPeak = findReverse(trend, r => r.isPeak);
	const lastValley = findReverse(trend, r => r.isValley);

	const drops = getDrops(trend);

	return { 
		trend,
		current: {
			value: currentValue,
			growth: (currentValue / previousClose) - 1
		},
		lastPeak: {
			value: lastPeak.value,
			delta: currentValue - lastPeak.value,
			growth: (currentValue / lastPeak.value) - 1
		},
		lastValley: {
			value: lastValley.value,
			delta: currentValue - lastValley.value,
			growth: (currentValue / lastValley.value) - 1
		},
		drops
	};
};