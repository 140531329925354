import React from 'react';

import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';

import { Header, Footer, DropDashboard } from '../../components';


const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			margin: theme.spacing(1),
			width: '25ch',
		},
	},
}));


const Component = ({
	match,
	history
}) => {
	// console.log('Wrapper');
	const classes = useStyles();

	const { params } = match;
	const { symbol } = params;

	const { register, handleSubmit } = useForm({
		defaultValues: { symbol }
	});

	const onSubmit = handleSubmit(
		data => history.push(`/drop-analysis/${(data.symbol || '').toUpperCase()}`)
	);

	return (
		<>
			<Header subtitle="Drop Analysis"></Header>
			<div className="Content">
				<Card>
					<form className={classes.root} noValidate autoComplete="off" onSubmit={onSubmit}>
						<TextField 
							id="standard-basic" 
							label="Symbol"
							placeholder="ex: SOXX"
							name="symbol" 
							inputRef={register} 
							inputProps={{ style: { textTransform: "uppercase" } }} 
							/>
						<Button type="submit" variant="contained" color="primary" size="small">Load</Button>
					</form>
				</Card>
				<DropDashboard symbol={symbol} />
			</div>
			<Footer></Footer>
		</>
	);
};

export default Component;