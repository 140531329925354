import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine } from 'recharts';
import NumberFormat from 'react-number-format';

const day = 14 * 60 * 60 * 1000;

const useStyles = makeStyles(theme => ({
	root: {
		marginTop: theme.spacing(2),
		padding: theme.spacing(1),
		textAlign: 'center'
	},
	wrapper: {
		height: '50vh',
	},
	tooltip: {
		backgroundColor: 'rgba(128, 128, 128, 0.1)',
		padding: theme.spacing(0.1, 1),
	}
}));


const getData = trend => trend.map(
	(r, i, a) => {
		const r1 = a[i - 1];
		return {
			...r,
			timeDelta: r1 ? (r.time - r1.time) / day : NaN,
			growth1: r1 ? 100 * r1.growth : NaN
		};
	}
).filter(
	r => r.isPeak
);


const CustomTooltip = ({ active, payload, className }) => {
	if (active) {
		const { payload: p } = payload[0];
		return (
			<div className={className}>
				<p>{p.date}</p>
				<p>
					<span>Drop: </span>
					<NumberFormat 
						value={p.growth1} 
						displayType="text" 
						decimalScale={2}
						suffix='%' 
						/>
				</p>
				<p>
					<span>Days to recover: </span>
					<NumberFormat 
						value={p.timeDelta} 
						displayType="text" 
						decimalScale={0}
						thousandSeparator={true}
						/>
				</p>
			</div>
		);
	}

	return null;
};



const Component = ({
	stats
}) => {

	const classes = useStyles();
	const { trend, lastPeak } = stats;

	const data = getData(trend);
	// console.log(data);
	
	return (
		<Card className={classes.root}>
			<h2>Prospects: Drop vs. Days to Recover</h2>
			<div className={classes.wrapper}>
				<ResponsiveContainer width="100%" height="100%">
					<ScatterChart data={data}>
						<CartesianGrid />
						<XAxis type="number" dataKey="growth1" name="drop" unit="%" />
						<YAxis type="number" dataKey="timeDelta" name="days" />
						<Scatter name="Recovery days" data={data} fill="#8884d8" />
						<Tooltip content={<CustomTooltip />} className={classes.tooltip}  />
						<ReferenceLine x={100 * lastPeak.growth} label="Current drop" stroke="#c00" strokeDasharray="4 4" />
					</ScatterChart>
				</ResponsiveContainer>
			</div>
		</Card>
	);
};

export default Component;
