import React from 'react';

import { Header, Footer, SectionCard } from '../../components';

// TODO: Get multiple sections from configuration
const Component = () => (
	<>
		<Header></Header>
		<div className="Content">
			<div className="SectionWrapper">
				<SectionCard></SectionCard>
			</div>
		</div>
		<Footer></Footer>
	</>
);

export default Component;